<template>
    <div class="">
        <el-dialog title="查看详情" :visible.sync="confitionDialog" width="1000px" @close="onCancel">
            <div class="dialog-inside lubanshopedialog">
                <!-- <div class="Souxiangqing">
                    <el-date-picker v-model="detailsTime" type="date" placeholder="选择日期" @change="selectDetailsTime">
                    </el-date-picker>
                    <el-input v-model="params" placeholder="请输入搜索参数" class="params"></el-input>
                    <el-input v-model="phone" placeholder="请输入搜索手机号" class="params"></el-input>
                    <el-button type="primary" icon="el-icon-search" @click="onsearcher()">搜索</el-button>
                </div> -->
                <div class="duoshao">
                    <div class="lvtotal">
                        总条数：{{particulars.lvtotal}} 条
                    </div>
                </div>
                <el-table :data="ErShopList" style="width: 100%">
                    <el-table-column prop="account" label="手机号">
                        <template slot-scope="scope">
                            <span v-if="scope.row.user==null">"暂无手机号"</span>
                            <span v-else>{{scope.row.user.account}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="client_ip" label="ip">
                        <!-- <template slot-scope="scope">
                            <div style="display: -webkit-box; cursor:pointer; -webkit-box-orient: vertical; -webkit-line-clamp: 2;overflow: hidden;color: #0075f7;" @click="onclick(scope.row.referrer)">{{scope.row.referrer}}</div>
                        </template> -->
                    </el-table-column>
                    <el-table-column prop="date" label="时间">
                    </el-table-column>
                </el-table>
                <el-pagination class="lv-page" :page-size="particulars.per_page" :pager-count="11" @current-change="particularsChange" background layout="prev, pager, next" :total="particulars.lvtotal" hide-on-single-page :current-page.sync="particulars.paramPage"></el-pagination>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            confitionDialog: false,
            ErShopList: [],
            lvtotal: null,
            particulars: {
                //详情分页
                per_page: 10, //一页多少条
                lvtotal: null, //共几条数据
                paramPage: 1, //表格当前第几页
            },
            source: "",
            pickerTime: ""
        };
    },
    methods: {
        onclick(url) {
            window.open(url);
        },
        // 详情分页
        particularsChange(val) {
            this.particulars.paramPage = val;
            this.onParticulars();
        },
        onCancel() {
            this.confitionDialog = false;
        },
        onParticulars() {
            var param = {
                source: this.source,
                date: this.pickerTime,
                per_page: this.particulars.per_page,
                page: this.particulars.paramPage
            }
            this.$service.get(this.$api.getWebLogParamsInfo, param, (res) => {
                if (res.code == "200") {
                    this.ErShopList = res.data.data.data;
                    this.particulars.lvtotal = res.data.data.total;
                }
            });
        },
        show(data, pickerTime) {
            this.source = data.source;
            this.pickerTime = pickerTime;
            this.onParticulars()
            this.confitionDialog = true;
        }
    },

    mounted() { },

    // methods: {
    // 测试用
    // onceshi(){
    // 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
    // 		console.log(123)
    // 		if(res.code == '200'){

    // 		}
    // 	})
    // },
    // }
};
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
</style>