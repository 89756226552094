<template>
    <div class="accesslog">
        <div class="padd20 borbut20" style="position: relative;">
            <div class="topSearchSou">
                <div class="searchdan">
                    <div class="title">统计时间：</div>
                    <el-date-picker v-model="pickerTime" type="date" placeholder="选择日期" @change="selectPickerTime">
                    </el-date-picker>
                    <!-- <el-input v-model="uri_name" placeholder="请输入搜索标题" class="params" @keyup.enter.native="onSearch()"></el-input> -->
                </div>
                <el-button type="primary" icon="el-icon-search" @click="onSearch()">搜索</el-button>
                <el-button class="Shuarefresh" @click="$router.go(0)" icon='el-icon-refresh-right'>重置数据</el-button>
            </div>
        </div>
        <div class="TableList padd20">
            <div class="duoshao">
                <div class="title">访问来源统计</div>
                <!-- <div class="lvtotal">
                    总条数：{{lvtotal}} 条
                </div> -->
                <div class="lvtotal">总条数：{{lvtotal}} 条 <div class="lvtotal riri">
                        每页显示:
                        <el-select v-model="per_page" placeholder="10" @change="selectTrigger()" style="margin-left:20px;margin-right:20px;width:80px">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                        条
                    </div>
                </div>
            </div>
            <el-table ref="filterTable" :data="ErShopList" style="width: 100%">
                <!-- <el-table-column prop="account" label="手机号">
                    <template slot-scope="scope">
                        <span v-if="scope.row.user==null">"暂无手机号"</span>
                        <span v-else>{{scope.row.user.account}}</span>
                    </template>
                </el-table-column> -->
                <el-table-column prop="source" label="来源">
                </el-table-column>
                <el-table-column prop="total" label="数量">
                </el-table-column>
                <!-- <el-table-column prop="" label="路径">
                    <template slot-scope="scope">
                        <div style="display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 2;
overflow: hidden;">{{scope.row.referrer}}</div>
                    </template>
                </el-table-column> -->
                <el-table-column prop="your_kind_balance" label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" @click="onDetails(scope.row)" style="margin-left:0px; font-size:10px;padding: 7px 7px;">查看详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <Particulars ref="Particulars"></Particulars>
        </div>
        <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @current-change="DialogCurrentChange" background layout="prev, pager, next, jumper" :total="lvtotal" hide-on-single-page :current-page.sync="paramPage"></el-pagination>
    </div>
</template>

<script>
import Particulars from "./particulars.vue";
export default {
    data() {
        return {
            uri_name: "",
            paramData: {}, //表格参数
            shopid: "", //参数
            pickerTime: this.getDay(-1), //日期参数
            per_page: 10, //一页多少条
            lvtotal: null, //共几条数据
            paramPage: 1, //表格当前第几页
            shop_title: "",
            ErShopList: [], //数据
            particularsList: [], //详情数据
            detailsTime: "", //详情日期
            // particulars: {
            //     //详情分页
            //     per_page: 8, //一页多少条
            //     lvtotal: null, //共几条数据
            //     paramPage: 1, //表格当前第几页
            // },
            detailsData: {}, //  详情数据
            params: "", //搜索参数
            particularsID: "",
            uri_name: "",
            phone: "",
            options: [
                {
                    value: 25,
                    label: "25",
                },
                {
                    value: 50,
                    label: "50",
                },
                {
                    value: 100,
                    label: "100",
                },
            ],
        };
    },

    mounted() {
        this.onDataList();
    },
    components: {
        Particulars
    },
    methods: {
        //查看详情
        onDetails(row) {
            this.$refs.Particulars.show(row, this.pickerTime);
        },
        //显示条数改变
        selectTrigger() {
            this.paramPage = 1;
            this.paramData.pageSize = this.per_page;
            this.onDataList();
        },
        // 获取昨天
        getDay(day) {
            var today = new Date();
            var targetday_milliseconds =
                today.getTime() + 1000 * 60 * 60 * 24 * day;
            today.setTime(targetday_milliseconds); //注意，这行是关键代码
            var tYear = today.getFullYear();
            var tMonth = today.getMonth();
            var tDate = today.getDate();
            tMonth = this.doHandleMonth(tMonth + 1);
            tDate = this.doHandleMonth(tDate);
            return tYear + "-" + tMonth + "-" + tDate;
        },
        doHandleMonth(month) {
            var m = month;
            if (month.toString().length == 1) {
                m = "0" + month;
            }
            return m;
        },
        selectPickerTime() {
            if (this.pickerTime != null) {
                this.pickerTime = this.dateFtt("yyyy-MM-dd", this.pickerTime);
            }
        },
        //页面搜索
        onSearch() {
            this.paramData = {
                date: this.pickerTime, //搜索日期
                uri_name: this.uri_name,
            };
            this.paramPage = 1;
            this.onDataList();
        },
        // 点击图片
        onImg(img) {
            window.open(
                "https://user.erlangcha.com/advances/Advimage?imageurl=" + img
            );
        },
        // 分页
        DialogCurrentChange(val) {
            this.paramPage = val;
            this.onDataList();
        },
        // 详情分页
        particularsChange(val) {
            this.particulars.paramPage = val;
            this.onParticulars();
        },
        //数据列表
        onDataList() {
            this.ErShopList = [];
            var param = this.paramData;
            param.page = this.paramPage;
            param.per_page = this.per_page;
            param.date = this.pickerTime;
            this.$service.get(this.$api.getWebLogParamsList, param, (res) => {
                if (res.code == "200") {
                    // console.log(res.data.data.data)
                    this.ErShopList = res.data.data.data;
                    this.lvtotal = res.data.data.total;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.accesslog {
    .lvtotal {
        line-height: 33px;
    }
    .img {
        width: 40px;
        height: 40px;
    }
    .Souxiangqing {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }
    .pan {
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1 !important;
        overflow: hidden;
    }
}
</style>
<style lang="scss">
.accesslog {
    .el-dialog__header {
        background: #0075f7;
        padding-bottom: 15px;
    }
    .el-dialog__title {
        color: #fff;
    }
    .el-input__icon {
        line-height: 35px;
    }
    .params {
        width: 180px !important;
        margin-left: 20px;
    }
    .el-table td,
    .el-table th {
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .is-in-pagination {
        width: 50px;
        margin-left: 10px;
        margin-right: 10px;
        text-align: center !important;
    }
    .el-pagination__jump .el-input__inner {
        text-align: center !important;
    }
    .img {
        width: 100px !important;
        height: 100px !important;
    }

    .el-table_1_column_2 .cell {
        text-align: left !important;
    }
}
.testtooltip {
    width: 200px;
}
</style>